const QUERIES = {
  USERS_LIST: 'users-list',
  TIPSTER_LIST: 'tipster-list',
  TYPES_LIST: 'tipster-type-list',
  COUNTRY_LIST: 'country-list',
  SPORT_LIST: 'sport-list',
  COMPETITION_LIST: 'competition-list',
  PARTICIPANTS_LIST: 'participants-list',
  BOOKIE_LIST: 'bookie-list',
  BET_LIST: 'bet-list',
  SERVICES_LIST: 'service-list',
  EVENT_LIST: 'event-list',
  PICK_LIST: 'pick-list',
}

const MARKET_QUOTES = [
  { label: "totals", value: 1 },
  { label: "teamTotal", value: 2 },
  { label: "moneyline", value: 3 },
  { label: "spreads", value: 4 }
]

const URL_FIXED_PARAMS = [
  'limit',
  'page',
  'search',
  'fresh',
  'order',
  'sort',
  'include',
]

export { QUERIES, MARKET_QUOTES, URL_FIXED_PARAMS }
